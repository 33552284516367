document.addEventListener('DOMContentLoaded', function() {
  document.body.className += ' loaded';

  var icon = document.getElementsByClassName('fa-send')[0]
  var submit = document.querySelector('button[type="submit"]')
  var form = document.getElementsByTagName('form')[0]
  var inputs = document.getElementsByTagName('input')

  submit.addEventListener('click', function () {
    icon.classList.remove('fa-send')
    icon.classList.add('fa-spin', 'fa-spinner')
  })

  for (var i=0; i < inputs.length; i++) {
    inputs[i].addEventListener('focus', function () {
      icon.classList.remove('fa-spin', 'fa-spinner', 'fa-check')
      icon.classList.add('fa-send')
      submit.lastChild.innerText = 'Send'
    })
  }

  window.howdyEvents.afterSend = function () {
    icon.classList.remove('fa-spin', 'fa-spinner')
    icon.classList.add('fa-check')
    submit.lastChild.innerText = 'Sent!'
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js');
  }
})
